<template>
  <navbar-detail-donasi @click="$router.go(-1)">
    <div class="navbar-brand">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
          fill="#F2F2F2"
        />
      </svg>
    </div>
    <h6 class="title-navbar">
      Search
    </h6>
  </navbar-detail-donasi>
  <div class="container">
    <div class="input-group mt-3 mb-3 search__campaign-banner">
      <span class="input-group-text icon__search" />
      <input
        type="text"
        class="form-control form__search"
        placeholder="Cari yang ingin kamu bantu"
        ref="searchData"
        v-model="searchData"
        @input="handleKeyUpSearch"
      />
    </div>

    <div v-if="searchData === '' || $route.query.keyword === ''" class="text-center">
      Ketikkan kata kunci program atau lembaga yang ingin anda cari
    </div>

    <div v-if="searchData !== ''" class="card card__search">
      <div class="card-body card__body-search">
        <div class="d-flex justify-content-between">
          <h3 class="text-gray-700 title__search">
            Campaign
          </h3>
          <router-link :to="`/campaigns`" class="show__all">
            Lihat Semua
          </router-link>
        </div>
        <div class="text-secondary mt-2 mb-4 description__search">
          Kami menemukan {{ campaings.length }} program dengan kata kunci <b>"{{ searchData }}"</b>
        </div>
        <div
          class="d-flex align-items-center position-relative mb-2"
          v-for="(camp, index) in campaings"
          :key="index"
        >
          <div class="flex-shrink-0 overflow-hidden w-1/4 rounded">
            <img :src="camp.wakafImage" :alt="camp.wakafName" class="w-full rounded" />
          </div>
          <div class="w-full d-flex flex-col flex-1 pl-3">
            <router-link :to="`/${camp.wakafUrl}`" class="decoration-none">
              <h3 class="font-medium text-sm overflow-hidden mb-0 color-black" style="max-height:2.8em;">
                {{ camp.wakafName }}
              </h3>
            </router-link>
            <div class="d-flex align-items-center text-xs mt-1 mb-2 align-middle leading-tight" v-if="camp.company !== null">
              <span class="mr-1">{{ camp.company.companyName }}</span>
              <svg
                class="h-2 w-2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12 12"
              >
                <path
                  d="M6 0C2.691 0 0 2.691 0 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6z"
                  fill="#0085FF"
                ></path>
                <path
                  d="M8.85 4.442L5.531 7.654a.518.518 0 01-.722 0L3.15 6.048a.482.482 0 010-.699.522.522 0 01.723 0L5.17 6.606l2.958-2.863a.522.522 0 01.722 0c.2.194.2.506 0 .7z"
                  fill="#FAFAFA"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="searchData !== ''" class="card card__search">
      <div class="card-body card__body-search">
        <div class="d-flex justify-content-between">
          <h3 class="text-gray-700 title__search">
            Lembaga
          </h3>
        </div>
        <div class="text-secondary mt-2 mb-4 description__search">
          Kami menemukan {{ companies.length }} lembaga dengan kata kunci <b>"{{ searchData }}"</b>
        </div>
        <div
          class="d-flex align-items-center position-relative mb-2"
          v-for="(comp, index) in companies"
          :key="index"
        >
          <div class="flex-shrink-0 overflow-hidden" style="width:50px; height:50px;">
            <img :src="comp.companyLogo" :alt="comp.companyName" class="w-full h-full rounded-full" />
          </div>
          <div class="w-full d-flex flex-col flex-1 pl-3">
            <h3 class="font-medium text-sm overflow-hidden mb-0" style="max-height:2.8em;">
              {{ comp.companyName }}
              <svg
                class="h-2 w-2 ml-3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12 12"
              >
                <path
                  d="M6 0C2.691 0 0 2.691 0 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6z"
                  fill="#0085FF"
                ></path>
                <path
                  d="M8.85 4.442L5.531 7.654a.518.518 0 01-.722 0L3.15 6.048a.482.482 0 010-.699.522.522 0 01.723 0L5.17 6.606l2.958-2.863a.522.522 0 01.722 0c.2.194.2.506 0 .7z"
                  fill="#FAFAFA"
                ></path>
              </svg>
            </h3>
            <div class="d-flex align-items-center text-xs mt-1 mb-2 align-middle leading-tight">
              <span>Akun terverifikasi</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="searchData !== ''" class="card card__search">
      <div class="card-body card__body-search">
        <div class="d-flex justify-content-between">
          <h3 class="text-gray-700 title__search">
            Kategori
          </h3>
        </div>
        <div class="text-secondary mt-2 mb-4 description__search">
          Kami menemukan {{ categories.length }} kategori dengan kata kunci
          <b>"{{ searchData }}"</b>
        </div>
        <div
          class="d-flex align-items-center position-relative mb-2"
          v-for="(cat, index) in categories"
          :key="index"
        >
          <div class="flex-shrink-0 overflow-hidden" style="width:50px; height:50px;">
            <img :src="cat.catImageCover" :alt="cat.catName" class="w-full h-full rounded" />
          </div>
          <div class="w-full d-flex flex-col flex-1 pl-3">
            <router-link :to="`/category/${cat.catUrl}`" class="decoration-none">
              <h3 class="font-medium text-sm overflow-hidden mb-0 color-black" style="max-height:2.8em;">
                {{ cat.catName }}
              </h3>
            </router-link>
            <div class="d-flex align-items-center text-xs mt-1 mb-2 align-middle leading-tight">
              <span>Kategori Aktif</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import NavbarDetailDonasi from '../components/TransactionComponent/NavbarTransaction.vue'

export default {
  components: { NavbarDetailDonasi },
  data() {
    return {
      searchData: this.$route.query.keyword ? this.$route.query.keyword : this.$route.query.hashtag,
      searchType: this.$route.query.keyword ? 'keyword': 'hashtag',
      campaings: [],
      categories: [],
      companies: [],
      outpuValue: null,
      timeout: null
    }
  },
  created() {
    this.getCampaignBySearch(this.searchData)
    this.getCompanyBySearch(this.searchData)
    this.getCategoryBySearch(this.searchData)
  },
  methods: {
    handleKeyUpSearch() {
      clearTimeout(
        this.timeout,
        ((this.campaings = []), (this.categories = []), (this.companies = []))
      )

      // const self = this
      this.timeout = setTimeout(() => {
        // this.$route.query.keyword = this.searchData
        // this.searchData = this.$route.query.keyword
        this.$router.replace({ query: { keyword: this.searchData } })
        // this.$route.query = { ...query, keyword: this.searchData }
        this.doFunction()
      }, 500)
    },
    doFunction() {
      this.getCampaignBySearch(this.searchData)
      this.getCategoryBySearch(this.searchData)
    },
    getCampaignBySearch(searchData) {
      // const url = new URL(window.location.href);
      const params = new URLSearchParams();
      params.append('q',searchData)
      params.append('ignore_description',1)
      this.$http
        .get(`${process.env.VUE_APP_API_URL}campaigns?${params.toString()}`)
        .then(response => {
          // this.$router.push({ query: { keyword: this.searchData } })
          const datas = response.data.data
          datas.forEach(value => {
            const objData = {
              wakafId: value.wakaf_id,
              wakafUrl: value.wakaf_url,
              wakafName: value.wakaf_name,
              wakafImage: value.wakaf_image_cover_url,
              company: value.company !== null ? {
                companyId: value.company.company_id,
                companyName: value.company.company_name
              } : null
            }

            this.campaings.push(objData)
          })
        })
        .catch(err => {
          console.error(err)
          swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Terjadi kesalahan'
            })
        })
    },
    getCompanyBySearch(searchData) {
      /* eslint-disable */
      const url = this.searchType === 'keyword' ? process.env.VUE_APP_API_URL + 'companies?name=' + searchData : process.env.VUE_APP_API_URL + 'companies/search-by-hashtag?q=' + searchData
      this.$http
        .get(url)
        .then(response => {
          if (typeof response.data.data !== 'undefined') {
            const datas = response.data.data
            datas.forEach(value => {
              const objData = {
                companyId: value.company_id,
                companyLogo: value.company_logo_url,
                companyName: value.company_name,
                companyUrl: value.company_url
              }

              this.companies.push(objData)
            })
          }
        })
        .catch(err => {
          console.error(err)
          swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Terjadi kesalahan'
            })
        })
      /* eslint-enable */
    },
    getCategoryBySearch(searchData) {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}campaign-categories?name=${searchData}`)
        .then(response => {
          const datas = response.data.data
          datas.forEach(value => {
            const objData = {
              catId: value.cat_id,
              catName: value.cat_name,
              catSortDescription: value.cat_short_description,
              catImageCover: value.cat_image_cover_url,
              catUrl: value.cat_url
            }

            this.categories.push(objData)
          })
        })
        .catch(err => {
          console.error(err)
           swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Terjadi kesalahan'
            })
        })
    }
  }
}
</script>
